import * as React from 'react';
import {Link} from 'gatsby';

import Layout from '../components/layout';
import TictactoeBoard from '../components/tictactoe/board';
import {Note} from '../components/literary-components';

import * as style from '../css/tictactoe.module.css';


function App() {
  return (
    <Layout pageTitle="Home" >
      <header className={style.AppHeader}>
        <h1>Play Tic-Tac-Toe!</h1>
        <p>Play against a simple Reinforcement Learning agent that uses Exhaustive Search to find the best possible action!</p>
        <p className="right">See on Github: <a href="https://github.com/luisds95/tictactoe-python">Agent</a>, <a href="https://github.com/luisds95/tictactoe-ui">UI</a></p>
      </header>
      <section id="Game">
        <TictactoeBoard />
      </section>
      <Note><p>Learn how it works at <Link to="/blog/cracking-tictactoe">Cracking TicTacToe</Link></p></Note>
    </Layout>
  );
}

export default App;
